import {isHomepage, isPost, getSessionGuid} from "./helpers.js";


class Api {

    constructor(options) {
        this.options = options
    }

    #getParams() {
        const options = this.options;
        const params = { post: options.post };

        if (options.memberId) {
            params.member = { id: options.memberId };
        } else {
            params.session = { guid: getSessionGuid() };
        }

        return params;
    }

    #isApiAllowed() {
        const allowedPages = [
            '/membership/',
            '/welcome-to-tangle/',
        ];
        return !!(isPost(this.options) || isHomepage()) || allowedPages.includes(window.location.pathname);
    }

    #validate() {
        const options = this.options;
        return options.apiKey && options.apiDomain;
    }

    request(path, params, callback) {
        if (!this.#validate()) {
            console.log('Outpost API key or Domain name is missing.');
            return;
        }
        if (!this.#isApiAllowed()) {
            console.log('Outpost API is not allowed on this page.');
            return;
        }

        const baseParams = this.#getParams();
        // merge baseParams with params
        const apiParams = Object.assign(params, baseParams);

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(apiParams),
            headers: {
                "Content-Type": "application/json",
            },
        };
        const url = `${this.options.apiDomain.replace(/\/$/, "")}/plugin-api${path}/${this.options.apiKey}/${this.options.memberId}`;

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(response => callback(response))
            .catch(error => console.log('[OUTPOST API ERROR]', error));
    }


}

export default Api
