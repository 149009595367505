import RenderBase from "./render-base.js";
import {loadJs, isHomepage, isPost, isMobile} from "./helpers.js";
import {DISABLE_SCROLL_CTA, HOMEPAGE_CTA} from "./const.js"
import MicroModal from "../modal/index.js";

class RenderCta extends RenderBase {


    constructor(options, site, data) {
        super(options, site, data);
        this.scrollDeepEvents = {}
    }

    #isSubscriptionBoxAllowed(box) {
        if (isPost(this.options)) {
            const post = this.options.post;
            return !post.tags || !post.tags.includes('#hide-outpost-cta');
        } else if (isHomepage()) {
            return HOMEPAGE_CTA.includes(box.type);
        }
        return false;
    }

    #renderHomepageCTA(key, box) {
        if (HOMEPAGE_CTA.includes(box.type)) {
            if (this.#isShowModalDisplayAllowed(box)) {
                this.#renderHomepagePopupCTA(key, box);
            }
        }
    }

    #renderHomepagePopupCTA(key, box) {
        this.loadAssets(key, box);
        this.#displayModalBox(box)
    }

    #displayModalBox(box) {
        if (!box.autoplacement.mode) {
            box.autoplacement.mode = 'manual'
        }

        switch (box.autoplacement.mode) {
            case 'manual':
                const selector = `outpost-${box.className}-${box.guid}`;
                this.#setupViewObserver(`#${selector}-anchor`, () => this.#displayCTAModal(box));
                break;
            case 'page_load':
                this.#displayCTAModal(box)
                break;
            case 'delay':
                setTimeout(() => this.#displayCTAModal(box), box.autoplacement.delaySeconds * 1000);
                break;
            case 'scroll':
                this.#setupScrollEvent(box);
                break;
        }
    }

    #setupScrollEvent(box) {
        if (box.autoplacement && box.autoplacement.scrollPercents) {
            this.scrollDeepEvents[box.guid] = () => {
                const body = document.body;
                const scrollPosition = window.scrollY;
                let scrollPercentage = (scrollPosition / (body.scrollHeight - window.innerHeight)) * 100;
                scrollPercentage = Math.round(scrollPercentage);

                if (scrollPercentage >= box.autoplacement.scrollPercents) {
                    this.#displayCTAModal(box);
                    window.removeEventListener("scroll", this.scrollDeepEvents[box.guid]);
                }
            };
            window.addEventListener("scroll", this.scrollDeepEvents[box.guid]);
        }
    }

    #isShowModalDisplayAllowed(box) {
        const modalClosedTime = +localStorage.getItem(`closedModal-${box.guid}`);

        if (modalClosedTime && box.settings?.displayHours) {
            const diff = (Date.now() - modalClosedTime) / 1000;
            return diff > box.settings.displayHours * 3600;
        }
        return true;
    }

    #displayCTAModal(box) {
        const selector = `outpost-${box.className}-${box.guid}`;
        const config = this.#getModalPopupConfig(box);

        document.activeElement.blur();
        MicroModal.show(selector, config);
    }

    #getModalPopupConfig(box) {
        const config = {
            onClose: modal => this.#setupModalCloseHandler(`${box.guid}`),
        };
        if (DISABLE_SCROLL_CTA.includes(box.type)) {
            config.disableScroll = true;
        }
        if (isMobile()) {
            config.disableFocus = true;
        }
        return config;
    }

    #setupModalCloseHandler(modalGuid) {
        localStorage.setItem(`closedModal-${modalGuid}`, Date.now());
    }

    #renderPostCTA(key, box) {
        const boxSelector = `[data-box-${key}]`;

        if (!document.querySelectorAll(boxSelector).length) {
            this.#ctaContainerAutoplacement(key, box);
        }

        document.querySelectorAll(boxSelector).forEach(btnContainer => {
            btnContainer.innerHTML = box.html;
            btnContainer.style.visibility = 'visible';
        });

        this.loadAssets(key, box);

        if (box.jss) {
            if (this.#isShowModalDisplayAllowed(box)) {
                this.#displayModalBox(box)
            }
        }
    }

    #setupViewObserver(selector, callback) {
        const element = document.querySelector(selector);
        if (!element) return;

        let eventExecuted = false;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !eventExecuted) {
                    eventExecuted = true;
                    observer.unobserve(entry.target);
                    callback();
                }
            });
        });

        observer.observe(element);
    }

    #ctaContainerAutoplacement(key, box) {
        const settings = box.autoplacement;
        let defaultContentSelector = 'main article:first-of-type';

        const newElement = document.createElement('div');
        newElement.className = `outpost-pub-container outpost-${box.className}`;
        newElement.setAttribute('data-box-' + key, '');

        if (document.querySelectorAll('.gh-content').length) {
            defaultContentSelector = '.gh-content';
        }

        const contentSelector = box.autoplacement.content || defaultContentSelector;
        const content = document.querySelectorAll(contentSelector);
        const selector = box.autoplacement.selector || 'p';
        let minParagraphs = box.autoplacement.minParagraphs || 3;
        if (minParagraphs < 3) {
            minParagraphs = 3;
        }

        const paragraphs = Array.from(content[0]?.querySelectorAll(selector) || []);
        const onlyParagraphs = Array.from(content[0]?.querySelectorAll('p') || []);

        if (onlyParagraphs.length < minParagraphs) {
            return;
        }

        const excludeSelectors = ['.content-cta', '.ctx-module-container'];
        const filteredParagraphs = this.#filterParagraphs(paragraphs, excludeSelectors);

        this.#insertCTAContainer(settings, filteredParagraphs, newElement);
    }

    #filterParagraphs(paragraphs, excludeSelectors) {
        return paragraphs.filter(paragraph => !excludeSelectors.some(sel => paragraph.closest(sel)));
    }

    #insertCTAContainer(settings, filteredParagraphs, newElement) {
        if (settings.direction === 'start') {
            settings.paragraph = Math.min(settings.paragraph, filteredParagraphs.length);
        } else {
            settings.paragraph = Math.max(settings.paragraph, filteredParagraphs.length + 1 - settings.paragraph);
        }

        filteredParagraphs.forEach((p, idx) => {
            if ((settings.after === 'after' && settings.paragraph === idx + 1) ||
                (settings.after !== 'after' && settings.paragraph === idx + 1)) {
                if (settings.after === 'after') {
                    p.parentNode.insertBefore(newElement, p.nextSibling);
                } else {
                    p.parentNode.insertBefore(newElement, p);
                }
            }
        });
    }


    render() {
        Object.keys(this.data).forEach(key => {
            const box = this.data[key];

            if (box.html && this.#isSubscriptionBoxAllowed(box)) {
                if (isHomepage()) {
                    this.#renderHomepageCTA(key, box);
                } else if (isPost(this.options)) {
                    this.#renderPostCTA(key, box);
                }
            }
        });

        this.attachSubscriptionFormEvents();
    }
















}

export default RenderCta
