export function loadCss(id, css) {
    const customCss = document.createElement("style");
    customCss.id = id;
    customCss.appendChild(document.createTextNode(css));
    document.getElementsByTagName("head")[0].appendChild(customCss);
}

export function loadJs(jss, callback) {
    jss.forEach(js => {
        const script = document.createElement('script');
        script.src = js;
        script.async = true;
        script.onload = callback;
        document.getElementsByTagName("head")[0].appendChild(script);
    });
}

export function loadFonts(fonts) {
    fonts.forEach(font => {
        const link = document.createElement('link');
        link.id = `outpost-pub-font-${font}`;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = `https://fonts.googleapis.com/css?family=${encodeURI(font)}`;
        document.getElementsByTagName("head")[0].appendChild(link);
    });
}

export function appendBody(add_body) {
    document.body.insertAdjacentHTML("beforeend", add_body);
}

export function appendScript(js_code) {
    let script = document.createElement('script');
    script.textContent = js_code;
    document.body.appendChild(script);
}

export function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function isHomepage() {
    return window.location.pathname === '/';
}

export function isPost(options) {
    const post = options.post;
    return post && post.id;
}

export function generateGuid() {
    return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
}

export function getSessionGuid() {
    if (window.localStorage.guid != null) {
        return window.localStorage.guid;
    } else {
        window.localStorage.guid = generateGuid();
        return window.localStorage.guid;
    }
}
